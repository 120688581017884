import { transactionBuySellStatus } from '@/views/apps/buy-sell/config/transactionTypes'

export default {
  [transactionBuySellStatus.wait_crypto_payment]: {
    theme: 'light-danger',
    text: 'Wait crypto payment',
  },
  [transactionBuySellStatus.wait_fiat_payment]: {
    theme: 'light-danger',
    text: 'Wait fiat payment',
  },
  [transactionBuySellStatus.paid]: { theme: 'light-success', text: 'Paid' },
  [transactionBuySellStatus.new]: { theme: 'light-primary', text: 'New' },
  [transactionBuySellStatus.error]: { theme: 'light-danger', text: 'Error' },
  [transactionBuySellStatus.pending_payout]: { theme: 'light-danger', text: 'Pending payout' },
  [transactionBuySellStatus.pending]: { theme: 'light-danger', text: 'Pending' },
  [transactionBuySellStatus.reject]: { theme: 'light-warning', text: 'Reject' },
  [transactionBuySellStatus.crypto_is_sending]: {
    theme: 'light-primary',
    text: 'Crypto is sending',
  },
  [transactionBuySellStatus.success]: { theme: 'light-success', text: 'Success' },
}
