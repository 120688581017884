<template>
  <div>
    <AppTable :rows="rows" :columns="columns" :is-loading="requestInProgress" :is-pagination="false">
      <template #default="{ column, row, formattedRow} = {}">
        <span v-if="column.field === 'type'">
          <span :variant="row.type.theme" class="text-white" pill>
            {{ row.type.text }}
          </span>
        </span>

        <span v-else-if="column.field === 'status'">
          <b-badge :variant="row.status.theme" class="text-white" pill>
            {{ row.status.text }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'reason'" class="reason">
          {{ row.reason }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="column.field === 'retry'" class="d-flex">
          <b-button
            class="mt-auto text-white ml-1"
            type="button"
            variant="info"
            @click="retry(row)"
            :disabled="!row.allowRetry || requestInProgress"
          >
            Retry
          </b-button>
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BBadge, BButton } from 'bootstrap-vue'

import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import { detailColumns as columns } from './config/tableConfig'

import AppTable from '@/components/AppTable.vue'
import { formattedDate } from '@/tools/util'
import statusRender from '@/mixins/statusRender'
import { transactionBuySellStatus } from '@/views/apps/buy-sell/config/transactionTypes'
import statusThemeCinfig from '@/views/apps/buy-sell/config/statusThemeCinfig'
export default {
  name: 'RolesCreate',
  components: {
    BBadge,
    AppTable,
    BButton,
  },
  mixins: [waitRequest, validationError, statusRender],
  data() {
    return {
      columns,
      transactionId: null,
      transaction: [],
    }
  },
  computed: {
    rows() {
      return this.transformBuySellData(this.transaction || {})
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    formattedDate,
    ...mapActions({
      fetchBuySellById: 'pgTransactions/fetchBuySellById',
      retryBuySellTransaction: 'pgTransactions/retryBuySellTransaction',
      fetchFailedBuySell: 'pgTransactions/fetchFailedBuySell',
    }),

    initState() {
      if (!this.$route.params.id) return

      this.transactionId = this.$route.params.id
      this.getBuySellById(this.transactionId)
      this.fetchFailedBuySell().catch(this.checkErrors)
      this.setStatusConfig(statusThemeCinfig)
    },

    retry(row) {
      const id = parseInt(row.id, 10)

      if (!row.allowRetry || this.requestInProgress) return

      this.waitRequest(() => {
        return this.retryBuySellTransaction({ id })
          .then(response => {
            this.transaction = response.data?.data
            this.fetchFailedBuySell().catch(this.checkErrors)
          })
          .catch(this.checkErrors)
      })
    },

    getBuySellById(transactionId) {
      this.fetchBuySellById({ id: transactionId })
        .then(response => {
          this.transaction = response.data?.data
        })
        .catch(this.checkErrors)
    },

    transformBuySellData(transaction) {
      const createdAt = transaction.createdAt ? this.formattedDate(transaction.createdAt) : ''
      const lastTry = transaction.lastTry ? this.formattedDate(transaction.lastTry) : ''

      const isSendCrypto = transaction.type === 'send_crypto'
      const type = {
        text: isSendCrypto ? 'Send crypto' : 'Receive crypto',
        theme: isSendCrypto ? 'light-primary' : 'light-success',
      }
      
      const status = {
        theme: this.getStatusTheme(transaction),
        text: this.getStatusText(transaction),
      }

      const network = transaction.network ? `/ ${transaction.network.network}` : ''
      const soldAmount = `${transaction.paidAmount || ''}`
      const soldFiatCryptoCurrency = `${transaction.paidCoin?.name || ''} ${transaction.paidCurrency?.nameCurrency || ''} ${
          isSendCrypto ? network : ''
      }`

      const boughtAmount = `${transaction.receivedAmount || ''}`
      const boughtFiatCryptoCurrency = `${transaction.receivedCoin?.symbol || ''} ${transaction.receivedCurrency?.nameCurrency || ''} ${!isSendCrypto ? network : ''}`

      const retryStatus = [transactionBuySellStatus.wait_crypto_payment, transactionBuySellStatus.wait_fiat_payment]

      const canRetry = retryStatus.includes(transaction.status)

      return [
        {
          ...transaction,
          createdAt,
          status,
          type,
          soldAmount,
          soldFiatCryptoCurrency,
          boughtAmount,
          boughtFiatCryptoCurrency,
          lastTry,
          canRetry,
        },
      ]
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
.reason {
  display: inline-block;
  width: 300px;
}
</style>
