import { statusTypes } from '@/views/apps/wallet-transactions/config/transactionTypes'

export default {
  data() {
    return {
      statusDefaultConfig: {
        [statusTypes.pending]: {
          theme: 'light-primary',
          text: 'pending',
        },
        [statusTypes.success]: {
          theme: 'light-success',
          text: 'success',
        },
        [statusTypes.reject]: {
          theme: 'light-info',
          text: 'reject',
        },
        [statusTypes.error]: {
          theme: 'light-danger',
          text: 'error',
        },
      },
    }
  },
  methods: {
    statusVariant(status, newParam = this.statusDefaultConfig) {
      const statusConfig = newParam

      return statusConfig[status] || statusConfig.success
    },

    getStatusText(item) {
      const currentStatus = this.statusVariant(item.status)
      return currentStatus.text
    },
    getStatusTheme(item) {
      const currentStatus = this.statusVariant(item.status)
      return currentStatus.theme
    },
    setStatusConfig(newConfig) {
      this.statusDefaultConfig = { ...this.statusDefaultConfig, ...newConfig }
    },
  },
}
