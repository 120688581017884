export default [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'Type',
    field: 'type',
  },
  {
    label: 'Sold amount',
    field: 'soldAmount',
  },
  {
    label: 'Sold fiat/crypto currency',
    field: 'soldFiatCryptoCurrency',
  },
  {
    label: 'Bought amount',
    field: 'boughtAmount',
  },
  {
    label: 'Bought fiat/crypto currency',
    field: 'boughtFiatCryptoCurrency',
  },
  {
    label: 'Fee',
    field: 'fee',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Details',
    field: 'details',
  },
]

export const detailColumns = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'Type',
    field: 'type',
  },
  {
    label: 'Sold amount',
    field: 'soldAmount',
  },
  {
    label: 'Sold fiat/crypto currency',
    field: 'soldFiatCryptoCurrency',
  },
  {
    label: 'Bought amount',
    field: 'boughtAmount',
  },
  {
    label: 'Bought fiat/crypto currency',
    field: 'boughtFiatCryptoCurrency',
  },
  {
    label: 'Fee',
    field: 'fee',
  },
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Last try',
    field: 'lastTry',
  },
  {
    label: 'Reason',
    field: 'reason',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Retry',
    field: 'retry',
  },
]
